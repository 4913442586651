export const kMobileComponyInfo = {
  jz: [
    {
      type: "p",
      children: [
        `归农创立于2015年，集团总部位于上海闵行区旭辉浦江国际3号楼。归农积极响应乡村振兴，首创农产品社群社交电商模式，以优质社群内容营销结合“三社方法论”(社群+社团+社区)法则，开创了“互联网+社交+内容”先河，塑造了极具人文情怀的独特归农文化，并创造良好的经济效益与社会效益。`,
      ],
    },
    {
      type: "img_list",
      children: [
        "https://websiteimg.gnso.cn/official-website/jituanjuzhen/002.jpg",
        "https://websiteimg.gnso.cn/official-website/jituanjuzhen/003.jpg",
        "https://websiteimg.gnso.cn/official-website/jituanjuzhen/005.jpg",
        "https://websiteimg.gnso.cn/official-website/jituanjuzhen/006.jpg",
        "https://websiteimg.gnso.cn/official-website/jituanjuzhen/008.jpg",
        "https://websiteimg.gnso.cn/official-website/jituanjuzhen/019.jpg",
        "https://websiteimg.gnso.cn/official-website/jituanjuzhen/033.jpg",
        "https://websiteimg.gnso.cn/official-website/jituanjuzhen/034.jpg",
      ],
    },
    {
      type: "p",
      children: [
        `归农商城首创“中央仓储+万店同源”农产品社交电商模式，通过“六度人脉”社交裂变，目前已有1100万会员。凭借市场作为保障的核心竞争力，归农在农业、生物制药、电子商务等三大产业均投资布局，已形成规模化集团，旗下有归农电商、蕙芷农旅、天下商邦、归农信息、香域医药、万非生物、拾塘农业等多家企业。`,
      ],
    },
    {
      type: "img_both",
      children: [
        require("@/assets/introduce/i_03.png"),
        require("@/assets/introduce/i_04.png"),
      ],
    },
    {
      type: "p",
      children: [
        `蒋宪彬，中国共产党员，归农集团创始人、天下商邦董事局主席、上海泰和商会会长。`,
      ],
    },
  ],
  gnc: [
    {
      type: "p",
      children: [
        `现代农业重规模化种植生产，却忽略了对食物品质的追求。人在吃“食物”的时候，不只是吸收营养，还在摄入“能量”与"信息"。 `,
        `受日本真实故事《一颗苹果的奇迹》启示，归农村由归农创始人蒋宪彬投资建立。该项目综合体坐落于江西吉安泰和灌西镇塅坑村古塘自然村，以乡村振兴为目标，秉持绿色、健康、生态的“自然农法”理念，以生态农业农产品为依托，以休闲观光和体验农业为模式，着力打造“农旅网”模式的生态农业，致力于成为环境更友好、生态更天然、村民更富有、村容更美观的“乡村振兴”样板。`,
      ],
    },
    {
      type: "img",
      children: [
        "https://websiteimg.gnso.cn/official-website/guinongcun/1.jpg",
      ],
    },
    {
      type: "p",
      children: [
        `该项目毗邻中国科学院地里研究所千烟洲工作站西北处，距离镇政府11公里。项目总投资1.45亿元，占地5000余亩，分两期实施。目前已投入资金5000多万元，建设特色绿色农产品基地2600余亩，涉及黑米/红米/蔬菜/水果（红心火龙果、紫色百香果、蓝莓、葡萄、西梅、黄桃、草莓、翠冠梨）/林下乌鸡养殖/香猪养殖/鸭/鹅等多个领域，品种达50余种。开发建设山场面积3000余亩，涉及果树/林下花海/药材/家禽养殖4个领域，建设观光休闲水面面积300多亩，建设游步道4000多米，建设民宿15栋。`,
        `项目全面建成后，生态农业种植面积将达到8000亩，带动基地增加就业岗位200人以上，年总产值将达到3000余万元，项目将形成特色农业观光区，丰富城乡居民的业余生活，带动群众增产增收，也将全面带动周边的产业发展，全力助推动乡村振兴。`,
      ],
    },
    {
      type: "img_list",
      children: [
        "https://websiteimg.gnso.cn/official-website/guinongcun/2.jpg",
        "https://websiteimg.gnso.cn/official-website/guinongcun/3.jpg",
        "https://websiteimg.gnso.cn/official-website/guinongcun/4.jpg",
        "https://websiteimg.gnso.cn/official-website/guinongcun/5.jpg",
        "https://websiteimg.gnso.cn/official-website/guinongcun/8.jpg",
        "https://websiteimg.gnso.cn/official-website/guinongcun/9.jpg",
        "https://websiteimg.gnso.cn/official-website/guinongcun/12.jpg",
        "https://websiteimg.gnso.cn/official-website/guinongcun/13.jpg",
        "https://websiteimg.gnso.cn/official-website/guinongcun/14.jpg",
        "https://websiteimg.gnso.cn/official-website/guinongcun/16.jpg",
        "https://websiteimg.gnso.cn/official-website/guinongcun/17.jpg",
        "https://websiteimg.gnso.cn/official-website/guinongcun/18.jpg",
        "https://websiteimg.gnso.cn/official-website/guinongcun/19.jpg",
        "https://websiteimg.gnso.cn/official-website/guinongcun/21.jpg",
      ],
    },
  ],
  swly: [
    {
      type: "p",
      children: [
        `四川金川，位于川西北高原的阿坝藏族羌族自治州西南部，素有“中国雪梨之乡”的美誉。`,
        `每年春天梨花盛开时节，可谓“忽如一夜春风来，千树万树梨花开”。深秋时，梨树叶染红层峦，那奇特的美景，吸引着无数中外游客、摄影爱好者翩然来临，依恋忘返。`,
        `金川雪梨是土生土长的品种，对本地的气候水土有着数百年的融合，几乎无需人工打理，人种天养，造就了其与众不同的天然品质。`,
      ],
    },
    {
      type: "img",
      children: [
        "https://websiteimg.gnso.cn/official-website/shiwailiyuan/7.jpg",
      ],
    },
    {
      type: "p",
      children: [
        `然而，由于金川地处偏远，交通不便，导致鲜果往外运输的成本与损耗风险比较大，在价格上与其他产地很难抗衡。再加上外观卖相并不太受市场欢迎，这导致金川雪梨连年滞销，“花果同树”。`,
        `民间一直流传着“食生梨清六腑之热，食熟梨滋五脏之阴”的说法。雪梨膏具有清心润肺、止咳消痰、清喉降火、除烦解渴、润燥消风、醒酒解毒等功效，经常喝雪梨膏则大有益处，并能促进食欲，帮助消化，是一款老少皆宜的饮品。在当地，熬制梨膏已经传承数百年。`,
        `归农通过社交电商，把雪梨熬制成梨膏销售，改变和重启了金川当地的雪梨产业，让当地果农吃上了“雪梨饭”……`,
      ],
    },
    {
      type: "img_list",
      children: [
        "https://websiteimg.gnso.cn/official-website/shiwailiyuan/1.jpg",
        "https://websiteimg.gnso.cn/official-website/shiwailiyuan/2.jpg",
        "https://websiteimg.gnso.cn/official-website/shiwailiyuan/3.jpg",
        "https://websiteimg.gnso.cn/official-website/shiwailiyuan/4.jpg",
        "https://websiteimg.gnso.cn/official-website/shiwailiyuan/5.jpg",
        "https://websiteimg.gnso.cn/official-website/shiwailiyuan/6.jpg",
        "https://websiteimg.gnso.cn/official-website/shiwailiyuan/8.jpg",
      ],
    },
  ],
  wmlh: [
    {
      type: "img",
      children: [
        "https://websiteimg.gnso.cn/official-website/wanmuluhui/1.jpg",
      ],
    },
    {
      type: "p",
      children: [
        `归农芦荟系列原料供应地为元江，位于中国彩云之南这片神奇美丽的热土。因地处红河领域元江中上游而得名。古有滇南雄镇之盛名，今有天然温室之美誉。`,
        `得天独厚的干热河谷气候，酝酿了中国唯一的野生芦荟发源地——云南库拉索芦荟种植基地。`,
        `这里是亚洲第一、世界第三的库拉索芦荟基地，也是归农万非芦荟产品的战略合作基地。种植面积近10000亩，年产8万吨鲜叶。`,
        `云南元江万亩芦荟种植基地，通过了欧盟有机认证。这是全球权威、公认的有机认证，认证标准非常严格。`,
      ],
    },
    {
      type: "img_list",
      children: [
        "https://websiteimg.gnso.cn/official-website/wanmuluhui/2.jpg",
        "https://websiteimg.gnso.cn/official-website/wanmuluhui/3.jpg",
        "https://websiteimg.gnso.cn/official-website/wanmuluhui/4.jpg",
        "https://websiteimg.gnso.cn/official-website/wanmuluhui/5.jpg",
        "https://websiteimg.gnso.cn/official-website/wanmuluhui/6.jpg",
        "https://websiteimg.gnso.cn/official-website/wanmuluhui/7.jpg",
        "https://websiteimg.gnso.cn/official-website/wanmuluhui/8.jpg",
        "https://websiteimg.gnso.cn/official-website/wanmuluhui/9.jpg",
        "https://websiteimg.gnso.cn/official-website/wanmuluhui/10.jpg",
        "https://websiteimg.gnso.cn/official-website/wanmuluhui/11.jpg",
        "https://websiteimg.gnso.cn/official-website/wanmuluhui/12.jpg",
        "https://websiteimg.gnso.cn/official-website/wanmuluhui/13.jpg",
      ],
    },
  ],
  ccps: [
    {
      type: "p",
      children: [
        `归农用6年时间打造了一条成熟的数字化采购体系、品控标准体系、高效率供应链体系。以“中央仓储+万店同源”模式，构建了四大仓储中心。`,
        `仓储配送严格做好抽样检测、快递开箱体验优化等，实现成本控制与快递配送质量两者的平衡。`,
      ],
    },
    {
      type: "btns",
      children: [
        {
          icon: require("@/assets/introduce/btn_buy_normal.png"),
          selectedIcon: require("@/assets/introduce/btn_buy_selected.png"),
          title: "数字化采购",
        },
        {
          icon: require("@/assets/introduce/bit_system_normal.png"),
          selectedIcon: require("@/assets/introduce/bit_system_selected.png"),
          title: "品控标准体系",
        },
        {
          icon: require("@/assets/introduce/btn_shopping_cart_normal.png"),
          selectedIcon: require("@/assets/introduce/btn_shopping_cart_selected.png"),
          title: "高效率供应链",
        },
      ],
    },
    {
      type: "title",
      children: [`中央仓储 万店同源`],
    },
    {
      type: "img",
      children: [
        "mobile/introduce/d_01.png",
        "mobile/introduce/d_02.png",
        "mobile/introduce/d_03.png",
        "mobile/introduce/d_04.png",
      ],
    },
  ],
};

export const KPCComponyLayout = [
  {
    title: "归农集团",
    name: "上海季芬电子商务有限公司",
    logos: [require("@/assets/logos/gn.png")],
    details: kMobileComponyInfo.jz,
  },
  // {
  //   title: "归农电商",
  //   name: "江西归农电子商务有限公司",
  //   logos: [
  //     require("@/assets/logos/gn.png"),
  //   ],
  //   details: [
  //     {
  //       type: 'p',
  //       children: [
  //         `归农集团旗下江西归农电子商务有限公司，主营业务为农牧产品电商销售。`,
  //         `相比其他电商模式，归农电商社交电商选品具有“高频复购”、“不容易买到”或者“不容易相信”等特征，成就了农产品深加工、芦荟、精油等多品类爆款产品。`,
  //       ]
  //     },
  //   ],
  // },
  {
    title: "蕙芷农旅",
    name: "上海蕙芷农业发展有限公司",
    logos: [require("@/assets/logos/hzzy.png")],
    details: [
      {
        type: "p",
        children: [
          `归农集团旗下上海蕙芷农业发展有限公司，作为归农微信商城、小程序经营主体，注册资本为1000万。`,
        ],
      },
    ],
  },
  {
    title: "万非生物",
    name: "万非(上海)生物科技有限公司",
    logos: [require("@/assets/logos/wf.png")],
    details: [
      {
        type: "p",
        children: [
          `万非（上海）生物科技有限公司，为归农与国际领先芦荟原料供应商万绿生物股份有限公司（830828）共同出资成立，主要经营销售芦荟系列的护肤品与日用品。 归农战略合作伙伴万绿生物股份有限公司，是国内芦荟工业原料领导品牌，亚洲最大的芦荟工业原料生产企业，产品远销欧美、东南亚等40多个国家和地区，产品占据国内芦荟原料市场份额的35%左右。 万绿股份是国内同行业中首家通过国际芦荟科学协会（IASC）设备设施及产品认证的企业，也是行业唯一一家参与国家芦荟制品行业标准起草的企业。`,
        ],
      },
    ],
  },
  {
    title: "香域医药",
    name: "香域(上海)医药科技有限公司",
    logos: [require("@/assets/logos/hzxc.png")],
    details: [
      {
        type: "p",
        children: [
          `幽兰生于深谷，不因无人而不芳。 归农成立香域（上海）医药科技有限公司，独立打造和运营“蕙芷”精油品牌。不同于市面上绝大多数品牌，蕙芷精油更强调整体思维，非西方主张的局部治标思维；主张标本兼顾，更重视人体规律，从而传递东方智慧的生活方式。`,
        ],
      },
    ],
  },
  {
    title: "古塘农业",
    name: "江西古塘农业发展有限公司",
    logos: [require("@/assets/logos/gt.png")],
    details: [
      {
        type: "p",
        children: [
          `江西古塘农业发展有限公司，主营农业项目开发、蔬菜、水果、坚果、花卉、苗木、谷物、油料、中药材种植、家禽、水产销售等。 古塘农业目前负责江西泰和县归农田园综合体建设项目，也就是“归农村”。该项目位于江西省泰和县灌溪镇古塘村，分两期工程实施。 一期工程主要是蔬菜、水果、花卉、药材等种植和家禽、水产养殖基地建设工程、生产生活基础设施和公司办公管理等设施建设工程。二期工程主要是道路工程和娱乐休闲、文化健身、美化绿化、 美丽乡村和产业带动建设工程。 该项目流转土地5000余亩，预计总投资额1.45亿元。`,
        ],
      },
    ],
  },
  // {
  //   title: "天下商邦",
  //   name: "上海桑邦文化传播有限公司",
  //   logos: [
  //     require("@/assets/logos/txsb.png"),
  //   ],
  //   details: [
  //     {
  //       type: 'p',
  //       children: [
  //         `天下商邦平台创立于2019年年底。应移动互联网+新商业业态背景孕育而生，以“侠义、豪情、担当”新英雄主义精神为理念，集聚广大天下商业有识之士、创业者、企业家群体，以“聚合、裂变、赋能”为方式，链接无限可能，打造“若同行、必不负”的社会团体，构筑创业者品质社区与生活圈。`
  //       ]
  //     },
  //   ],
  // },
  {
    title: "归农技术",
    name: "上海归农信息技术有限公司",
    logos: [require("@/assets/logos/gn.png")],
    details: [
      {
        type: "p",
        children: [
          `归农技术为归农集团旗下信息技术咨询服务平台，主要业务为技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广等。`,
        ],
      },
    ],
  },
  // {
  //   title: "西云数据",
  //   name: "西云数据科技(上海)有限公司",
  //   logos: [
  //     require("@/assets/logos/gn.png"),
  //   ],
  //   details: [
  //     {
  //       type: 'p',
  //       children: [
  //         `西云数据为归农集团旗下数据信息服务平台，主营业务为数据科技、网络、信息、智能化、计算机科技领域内的技术开发、技术咨询、技术服务、技术转让等业务。`,
  //       ]
  //     },
  //   ],
  // },
  // {
  //   title: "购享传媒",
  //   name: "上海购享文化传媒有限公司",
  //   logos: [require("@/assets/logos/gn.png")],
  //   details: [
  //     {
  //       type: "p",
  //       children: [
  //         `归农集团旗下文化影视传媒公司，承接集团内外文创项目策划、文案、设计、视频、活动等各类服务。`,
  //       ],
  //     },
  //   ],
  // },
];
