
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
  data() {
    return {
      showBg: true,
      words: ["出", "发", "即", "是", "归", "途", "..."],
      bgURL: "https://websiteimg.gnso.cn/official-website/video-cover/shouye.jpg",
      videoURL:
        "https://websiteimg.gnso.cn/%E5%BD%92%E5%86%9CMV%E6%9C%80%E7%BB%88%E7%89%88.mp4",
    };
  },
  methods: {
    doPlayVideo() {
      this.showBg = false;
    },
  },
})
export default class Home extends Vue {}
