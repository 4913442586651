export const kCompanyCulturePageContent = {
  name: "影视文艺",
  list: [
    {
      type: "video",
      title: "归农主题曲",
      videoURL: "https://websiteimg.gnso.cn/%E5%BD%92%E5%86%9CMV%E6%9C%80%E7%BB%88%E7%89%88.mp4",
      poster: "https://websiteimg.gnso.cn/official-website/video-cover/guinongzhutiqu.jpg",
      words: ["为什么要归农？", "不是因为要走的更远", "而是要离得更近"],
    },
    // {
    //   type: "video",
    //   title: "天下商邦",
    //   videoURL: "https://websiteimg.gnso.cn/%E5%A4%A9%E4%B8%8B%E5%95%86%E9%82%A6%20MV%20%E8%87%B4%E6%95%AC%E7%89%88.mp4",
    //   poster: "https://websiteimg.gnso.cn/official-website/video-cover/tianxiashangbang.jpg",
    //   words: ["世界上只有一种英雄主义", "就是看清生活的真相之后", "依然热爱生活"],
    // },
    {
      type: "book",
      title: "《归农--移动互联网精神与内容电商启示录》",
      ps: [
        `本书以上海季芬电子商务有限公司创始人蒋宪彬的创业史为线索，用一系列精彩故事，真实深刻地反映了国内电子商务发展的一个历史侧面，用具体案例，揭示了移动互联时代精神和传统
          商业思维之间，人们消费行为的本质差异，分析了蒋宪彬创新提出"内容电商”模式和以此成功运营移动商城"归农”的内在逻辑，以及怎样以归农思想为商业未来的发展提供了某种全新的可能。`,
        `小故事蕴含大意义，普通人展开了一幅波澜壮阔的历史画卷，揭示了商业的本质。因此这是一本好看的书,是一本让人感动的书，具有广泛的启发意义和借鉴价值。`,
      ],
      img: require("@/assets/guinong/book.png"),
      btnText: "了解详情",
      link: `https://shop13338087.wxrrd.com/login?goods/124454676?uid=227481633&suid=227481633&scene=1&sharetime=1637559050094`,
    },
    {
      type: "aduio",
      title: "《阿妈的雪梨膏》",
      img: require("@/assets/guinong/audio.png"),
      videoURL:
        "https://websiteimg.gnso.cn/%E5%BD%92%E5%86%9CMV%E6%9C%80%E7%BB%88%E7%89%88.mp4",
      ps: [
        `阿妈的雪梨膏 - 班确词：木西曲：辰尘/老k`,
        `制作：成都华宇大地文化传播有限公司`,
        `出品：杜宇`,
        `高高的雪山 守护着家园`,
        `纯净的甘泉 滋润着高原`,
        `飘动的经幡 祈福着平安`,
        `这就是我的家乡 世外梨园`,
        `千万朵梨花 开在蓝天下`,
        `大山的孩子 长大离开家`,
        `阿妈把祝福 用甘泉融化`,
        `装进孩儿的行囊 伴儿去天涯`,
        `阿妈的雪梨膏 是雪域的珍宝`,
        `那童年的味道 永远忘不了`,
        `阿妈的雪梨膏 是古老的歌谣`,
        `那幸福的味道 甘甜而美妙`,
        `高高的雪山 守护着家园`,
        `纯净的甘泉 滋润着高原`,
        `飘动的经幡 祈福着平安`,
        `这就是我的家乡 世外梨园`,
        `千万朵梨花 开在蓝天下`,
        `大山的孩子 长大离开家`,
        `阿妈把祝福 用甘泉融化`,
        `装进孩儿的行囊 伴儿去天涯`,
        `阿妈的雪梨膏 是雪域的珍宝`,
        `那童年的味道 永远忘不了`,
        `阿妈的雪梨膏 是古老的歌谣`,
        `那幸福的味道 甘甜而美妙`,
        `阿妈的雪梨膏 是雪域的珍宝`,
        `那童年的味道 永远忘不了`,
        `阿妈的雪梨膏 是古老的歌谣`,
        `那幸福的味道 甘甜而美妙`,
        `走过了春秋 走过冬夏`,
        `伴随我长大 平安走天涯`,
        `伴随我长大 平安走天涯`,
      ],
    },
    {
      type: "video",
      title: "品牌专题片",
      videoURL: "https://websiteimg.gnso.cn/%E7%A5%9E%E5%A5%87%E7%9A%84%E8%8A%A6%E8%8D%9F%20%E4%B8%87%E9%9D%9E.mp4",
      poster: "https://websiteimg.gnso.cn/official-website/video-cover/pinpaizhuantiye.jpg",
      words: ["心系自然", "美由心生"],
    },
    {
      type: "painter",
      title: "归农画家",
      details: [
        {
          name: "汪辉",
          title: "作者：汪辉\n1964年7月出生于江西贵溪，1986年毕业于江西师范大学美术系。南昌大学艺术与设计学院教授、美术创作中心主任/中国美术家协会会员/江西省美术家协会常务理事。",
          conver: require("@/assets/painter/wh.jpg"),
        },
        {
          name: "曾国华",
          title: `作者：曾国华\n1972年11月出生，江西万年人。2006年毕业于江西师范大学美术学院，油画方向硕士，同年任教于南昌大学建筑系。江西省写生学会党支部书记、会长/中法艺术家协会副会长。2020年获“中华文化复兴践行者”称号。`,
          conver: require("@/assets/painter/zgh.jpg"),
        },
        {
          name: "作者：刘亚雄",
          title: `作者：刘亚雄\n南昌大学艺术与设计学院副教授、硕士生导师/江西省美术家协会会员/江西省写生学会秘书长。`,
          conver: require("@/assets/painter/lyx.jpg"),
        },
        {
          name: "作者：彭建斌",
          title: `作者：彭建斌\n江西财经大学教授、硕士生导师/中国美术家协会会员/中国油画学会会员/江西省油画艺委会委员/江西省文化艺术学科带头人`,
          conver: require("@/assets/painter/pjb.jpg"),
        },
        {
          name: "作者：张相森",
          title: `作者：张相森\n中国美术家协会会员/江西省美协水彩、粉画艺术委员会秘书长/江西省写生学会副会长/江西省工艺美术协会文创产品委员会理事江西财经大学艺术学院副教授、硕士研究生导师/江西财经大学书画院院长/江西财经大学博雅艺术教育中心副主任
            `,
          conver: require("@/assets/painter/zxs.jpg"),
        },
        {
          name: "作者：苏翰宇",
          title: `作者：苏翰宇\n江西师范大学副教授/江西省美术家协会理事。`,
          conver: require("@/assets/painter/shy.jpg"),
        },
        {
          name: "作者：陈锋",
          title: `作者：陈锋\n江西萍乡人，江西师范大学美术学院美术学专业毕业。\n萍乡学院艺术学院教授、院长/中央美术学院油画系访问学者/中国美术家协会会员/萍乡市美术家协会主席`,
          conver: require("@/assets/painter/cf.jpg"),
        },
        {
          name: "作者：邓坤",
          title: `作者：邓坤\n江西萍乡人，民盟盟员，江西师范大学美术学院美术教育专业毕业，2010年中央美术学院访问学者。\n萍乡学院艺术学院教授、副院长／中国壁画学会会员／江西省美术家协会会员／江西省综合材料绘画艺委会委员／江西省写生学会常务理事`,
          conver: require("@/assets/painter/dk.jpg"),
        },
        {
          name: "作者：曾群浪",
          title: `作者：曾群浪\n江西南丰人，九三学社社员\n抚州职业技术学院教授/江西省美术家协会常务理事/江西省综合材料绘画艺委会委员/江西省写生学会常务理事/抚州市美术家协会主席`,
          conver: require("@/assets/painter/zql.jpg"),
        },
        {
          name: "作者：屠春娟",
          title: `作者：刘亚雄\n毕业于江西师范大学美术系，江西省美术家协会会员，现就职于鹰潭市月湖区美术馆。`,
          conver: require("@/assets/painter/tcj.jpg"),
        },
        {
          name: "作者：吴涛",
          title: `作者：吴涛\n江西宜春人，毕业于江西师范大学美术系，现就职于樟树市文化馆。\n江西省美术家协会会员/江西省写生学会副秘书长/江西省素描协会会员/樟树市美术家协会理事`,
          conver: require("@/assets/painter/wt.jpg"),
        },
        {
          name: "作者：蔡武平",
          title: `作者：蔡武平\n毕业于江西师范大学，曾进修于中国美协和中央美院油画研修班。江西省美术家协会会员/江西省油画艺委会委员/江西鹰潭月湖区美术馆馆长`,
          conver: require("@/assets/painter/cwp.jpg"),
        },
        {
          name: "作者：何志辉",
          title: `作者：何志辉\n任教于江西现代职业技术学院设计学院。\n中国美术家协会会员/江西省美术家协会理事/漆言画社成员
            `,
          conver: require("@/assets/painter/hzh.jpg"),
        },
        {
          name: "作者：尧曦燕",
          title: `作者：尧曦燕\n景德镇陶瓷学院陶艺专业、中央民族大学研究生班研修油画\n江西省美术家协会会员/杏坛青年油画家/江西省写生学会副秘书长/中国女子当代画会会员/快乐中国写生俱乐部会员`,
          conver: require("@/assets/painter/yxy.jpg"),
        },

      ],
    },
  ],
}