import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/home/videoPlay.png'


const _withScopeId = (n: any) => (_pushScopeId("data-v-16c22109"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page_container_none_tb_padding" }
const _hoisted_2 = {
  key: 0,
  id: "home_content"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = { id: "home_play_bg_content" }
const _hoisted_5 = { id: "home_play_bg" }
const _hoisted_6 = { id: "home_words" }
const _hoisted_7 = {
  key: 1,
  id: "vide_content",
  controls: "",
  autoplay: "true",
  name: "media"
}
const _hoisted_8 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showBg)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("img", {
            src: _ctx.bgURL,
            id: "home_bg_content"
          }, null, 8, _hoisted_3),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("img", {
                id: "play_img",
                src: _imports_0,
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.doPlayVideo && _ctx.doPlayVideo(...args)))
              })
            ]),
            _createElementVNode("div", _hoisted_6, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.words, (word, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  id: "home_word",
                  key: `word_${index}`
                }, _toDisplayString(word), 1))
              }), 128))
            ])
          ])
        ]))
      : (_openBlock(), _createElementBlock("video", _hoisted_7, [
          _createElementVNode("source", {
            src: _ctx.videoURL,
            type: "video/mp4"
          }, null, 8, _hoisted_8)
        ]))
  ]))
}